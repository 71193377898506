
import { Component, Emit, Vue } from "vue-property-decorator";
import {
  Customer,
  CustomersApi,
} from "@fundraisingbureauet/admin-typescript-client";
import { AuthInterceptor } from "@/config";
import ElementList, {
  ListColumn,
} from "@/components/shared/layout/ElementList.vue";
import CustomerDialog from "@/components/customers/CustomerDialog.vue";
import CustomerModal from "@/components/customers/CustomerModal.vue";

@Component({
  components: { CustomerDialog, CustomerModal, ElementList },
})
export default class Customers extends Vue {
  customers: Array<Customer> = [];
  currentCustomer?: Customer | null = null;
  search = "";
  state = true;
  newCustomer = false;
  customerToEdit: Customer | null = null;
  columns: ListColumn<Customer>[] = [
    {
      header: "BusinessCode",
      key: "businessCode",
      field: "businessCode",
    },
    {
      header: "Name",
      key: "name",
      field: "name",
    },
    {
      header: "Active",
      key: "active",
      field: "active",
    },
  ];

  get GetCustomers() {
    return this.customers;
  }

  set GetCustomers(value) {
    this.customers = value;
  }

  get GetCurrentCustomer() {
    return this.currentCustomer;
  }

  set GetCurrentCustomer(value) {
    this.currentCustomer = value;
  }

  /**
   * Initialise
   */
  created() {
    this.refresh();

    //  Initialise modal by query
    window.addEventListener("popstate", () => this.initializeModel(), false);
  }

  @Emit()
  added() {
    this.customerToEdit = null;
    this.refresh();
  }

  @Emit()
  customerUpdated(customer: Customer) {
    this.GetCurrentCustomer = customer;
  }

  closeCustomerModal() {
    this.customerToEdit = null;
  }

  //  TODO
  createNewCustomer() {
    this.customerToEdit = {
      name: "",
      podioId: -1,
      economicId: -1,
      businessCode: "",
      email: "",
      msisdn: "",
      address: "",
      postCode: "",
      city: "",
      countryCode: "",
      zendeskId: "",
      gdprInformation: "",
      website: "",
    };
  }

  private editCustomer(customer: Customer) {
    this.currentCustomer = customer;

    this.viewEntity(this.currentCustomer.invoiceCustomerId?.toString());
  }

  private viewEntity(invoiceCustomerId: string | undefined) {
    //  Skip double navigation
    if (
      this.$route.query &&
      this.$route.query["invoiceCustomerId"] == invoiceCustomerId
    ) {
      return false;
    }

    const query: { [key: string]: any } = {};
    query["invoiceCustomerId"] = invoiceCustomerId;

    this.$router.push({ query });
  }

  private initializeModel() {
    if (this.$route.query.invoiceCustomerId) {
      const invoiceCustomerId = this.$route.query.invoiceCustomerId.toString();
      const search: any = this.$route.query.search;

      //  Query with invoiceCustomerId
      if (invoiceCustomerId) {
        //  Get merchant
        const customer = this.customers.find(
          (value) => value.invoiceCustomerId === parseInt(invoiceCustomerId)
        );
        this.currentCustomer = customer;

        //  Open modal
        this.viewEntity(invoiceCustomerId as string);
      }
    }
  }

  filterCustomers(customer: Customer, filter: string, state: boolean) {
    if (filter === "") {
      return customer.active == state;
    } else {
      return (
        customer.active == state &&
        (customer.name?.toLowerCase().includes(filter.toLowerCase()) ||
          customer.businessCode?.toString().includes(filter.toLowerCase()))
      );
    }
  }

  private refresh() {
    new CustomersApi(AuthInterceptor.Instance)
      .getCustomers()
      .subscribe((value) => {
        this.customers = value;
        this.initializeModel();
      });
    this.newCustomer = false;
  }

  private closeModal(updated: boolean) {
    //  Reset current
    this.currentCustomer = null;

    const query: any = {};

    //  Search
    if (this.search) {
      query.search = this.search;
    }

    if (updated) {
      this.$router.push(
        {
          name: "CustomerList",
          query,
        },
        () => {
          this.refresh();
        }
      );
    } else {
      this.$router.replace({
        name: "CustomerList",
        query,
      });
    }
  }
}
