
import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import Modal from "@/components/shared/layout/Modal.vue";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import {
  CustomerApi,
  JobsApi,
  UpsertCustomer,
} from "@fundraisingbureauet/admin-typescript-client";
import { AuthInterceptor } from "@/config";
import { eventHub } from "@/router";

extend("required", required);

@Component({
  components: { Modal, ValidationProvider, ValidationObserver },
})
export default class CustomerDialog extends Vue {
  @Prop({ required: true }) customer!: UpsertCustomer;
  @Prop({ required: false }) invoiceCustomerId!: number;
  @Prop({ required: false, default: false }) isNew!: boolean;

  newCustomer!: UpsertCustomer | null;
  podioIdLoading = false;
  podioIdValidated = false;
  podioIdResponse = "";
  economicIdLoading = false;
  economicIdValidated = false;
  economicIdResponse = "";
  zendeskIdLoading = false;
  zendeskIdValidated = false;
  zendeskIdResponse = "";

  get setCustomerName() {
    if (this.isNew) {
      return "New Customer";
    }

    return `Edit: ${this.customer.name}`;
  }

  created() {
    if (!this.isNew) {
      this.checkPodioId();
      this.checkEconomicId();
      this.checkZendeskId();
    }
  }

  checkPodioId() {
    if (!this.customer.podioId) {
      this.podioIdLoading = false;
      this.podioIdValidated = false;
      this.podioIdResponse = "";
    } else {
      this.podioIdLoading = true;
      const api = new CustomerApi(AuthInterceptor.Instance);

      //  Create customer
      api
        .getPodioCustomer({ podioCustomerId: this.customer.podioId })
        .subscribe(
          (value) => {
            this.podioIdLoading = false;
            this.podioIdValidated = true;
            this.podioIdResponse = value.name;
          },
          (error) => {
            this.podioIdLoading = false;
            this.podioIdValidated = false;
            this.podioIdResponse = "Invalid";
          }
        );
    }
  }

  checkEconomicId() {
    if (!this.customer.economicId) {
      this.economicIdLoading = false;
      this.economicIdValidated = false;
      this.economicIdResponse = "";
    } else {
      this.economicIdLoading = true;
      const api = new CustomerApi(AuthInterceptor.Instance);

      //  Create customer
      api
        .getEconomicCustomer({ economicCustomerId: this.customer.economicId })
        .subscribe(
          (value) => {
            this.economicIdLoading = false;
            this.economicIdValidated = true;
            this.economicIdResponse = value.name;
          },
          (error) => {
            this.economicIdLoading = false;
            this.economicIdValidated = false;
            this.economicIdResponse = "Invalid";
          }
        );
    }
  }

  checkZendeskId() {
    if (!this.customer.zendeskId) {
      this.zendeskIdLoading = false;
      this.zendeskIdValidated = false;
      this.zendeskIdResponse = "";
    } else {
      this.zendeskIdLoading = true;
      const api = new CustomerApi(AuthInterceptor.Instance);

      //  Create customer
      api
        .getZendeskCustomer({ zendeskCustomerId: this.customer.zendeskId })
        .subscribe(
          (value) => {
            this.zendeskIdLoading = false;
            this.zendeskIdValidated = true;
            this.zendeskIdResponse = value.name;
          },
          (error) => {
            this.zendeskIdLoading = false;
            this.zendeskIdValidated = false;
            this.zendeskIdResponse = "Invalid";
          }
        );
    }
  }

  addCustomer(validate: () => Promise<any>) {
    validate().then(() => {
      if (
        !this.podioIdValidated ||
        !this.podioIdValidated ||
        !this.podioIdValidated
      ) {
        if (!this.podioIdValidated) {
          this.podioIdResponse = "Not validated";
        }
        if (!this.economicIdValidated) {
          this.economicIdResponse = "Not validated";
        }
        if (!this.zendeskIdValidated) {
          this.zendeskIdResponse = "Not validated";
        }

        return false;
      }

      this.newCustomer = {
        name: this.customer.name,
        podioId: this.customer.podioId,
        economicId: this.customer.economicId,
        businessCode: this.customer.businessCode,
        email: this.customer.email,
        msisdn: this.customer.msisdn,
        address: this.customer.address,
        postCode: this.customer.postCode,
        city: this.customer.city,
        countryCode: this.customer.countryCode,
      };

      if (this.customer.zendeskId) {
        this.newCustomer.zendeskId = this.customer.zendeskId;
      }

      if (this.customer.gdprInformation) {
        this.newCustomer.gdprInformation = this.customer.gdprInformation;
      }

      if (this.customer.website) {
        this.newCustomer.website = this.customer.website;
      }

      const api = new CustomerApi(AuthInterceptor.Instance);

      //  Create customer
      api
        .createCustomer({
          upsertCustomer: this.newCustomer,
        })
        .subscribe(
          (value) => {
            this.$emit("added", value);
          },
          (error) => {
            eventHub.$emit("httpError", error);
          }
        );
    });
  }

  updateCustomer(validate: () => Promise<any>) {
    if (!this.podioIdValidated || !this.economicIdValidated) {
      eventHub.$emit(
        "warningToast",
        "Podio Id and Economic Id cannot be empty"
      );
      return false;
    }

    validate().then((validated) => {
      if (validated) {
        if (!this.podioIdValidated || !this.economicIdValidated) {
          eventHub.$emit(
            "warningToast",
            "Podio Id and Economic Id cannot be empty"
          );
          return false;
        }

        this.newCustomer = {
          name: this.customer.name,
          podioId: this.customer.podioId,
          economicId: this.customer.economicId,
          businessCode: this.customer.businessCode,
          email: this.customer.email,
          msisdn: this.customer.msisdn,
          address: this.customer.address,
          postCode: this.customer.postCode,
          city: this.customer.city,
          countryCode: this.customer.countryCode,
        };

        if (this.customer.zendeskId) {
          this.newCustomer.zendeskId = this.customer.zendeskId;
        }

        if (this.customer.gdprInformation) {
          this.newCustomer.gdprInformation = this.customer.gdprInformation;
        }

        if (this.customer.website) {
          this.newCustomer.website = this.customer.website;
        }

        const api = new CustomerApi(AuthInterceptor.Instance);

        //  Update customer
        api
          .updateCustomer({
            invoiceCustomerId: this.invoiceCustomerId,
            upsertCustomer: this.newCustomer,
          })
          .subscribe(
            (value) => {
              if (this.isNew) {
                //  Toast
                eventHub.$emit("successToast", `Customer added`);
                this.$emit("added", value);
              } else {
                //  Toast
                eventHub.$emit("successToast", `Customer updated`);
                this.$emit("customerUpdated", value);
              }
            },
            (error) => {
              eventHub.$emit("httpError", error);
            }
          );
      }
    });
  }

  @Emit()
  closed(event: any) {
    this.$emit("closed");
  }
}
