
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  InvoiceDraftApi,
  InvoiceDraft,
  InvoiceLine,
  GetInvoiceDraftsRequest,
  GetInvoiceDraftsStateEnum,
} from "@fundraisingbureauet/admin-typescript-client";
import { AuthInterceptor } from "@/config";
import { eventHub } from "@/router";
import ComponentInvoiceLine from "@/components/invoice/InvoiceLine.vue";
import ComponentInvoiceDraft from "@/components/invoice/InvoiceDraft.vue";
import InvoiceList from "@/components/invoice/InvoiceLineList.vue";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", required);

@Component({
  components: {
    InvoiceList,
    ComponentInvoiceLine,
    ComponentInvoiceDraft,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class InvoiceDraftList extends Vue {
  @Prop({ required: false }) invoiceCustomerId!: number;
  @Prop({ required: false, default: false }) showDate!: boolean;
  @Prop({ required: false, default: true }) loadDrafts!: boolean;
  @Prop({ required: false, default: true }) showHeader!: boolean;
  @Prop({ required: false, default: true }) addedInvoiceDrafts?:
    | InvoiceDraft[]
    | null;
  @Prop({ required: false, default: null }) search?: string;
  @Prop({ required: false, default: "" }) customerName!: string;

  invoiceDrafts?: InvoiceDraft[] | null = [];

  getInvoiceDraftsRequest: GetInvoiceDraftsRequest = {
    state: "NEW" as GetInvoiceDraftsStateEnum,
  };

  invoiceDraftsLoading = false;
  invoiceDraftsLoaded = false;
  invoiceDraftsErrored = false;
  invoiceDraftsPage = 1;
  invoiceDraftsSearch = "";

  get getInvoiceDrafts() {
    if (!this.loadDrafts) {
      this.invoiceDrafts = this.addedInvoiceDrafts;
    }

    if (this.search) {
      let searchString = "";
      if (this.search) {
        searchString = this.search;
      }

      return this.invoiceDrafts?.filter(
        (draft) =>
          draft.invoiceDraftId == parseInt(searchString) ||
          !draft.invoiceCustomerName ||
          draft.invoiceCustomerName
            ?.toLowerCase()
            .includes(searchString.toLowerCase())
      );
    }

    return this.invoiceDrafts;
  }

  set getInvoiceDrafts(value) {
    this.invoiceDrafts = value;
  }

  /**
   * Initialise
   */
  created() {
    if (this.loadDrafts) {
      this.loadInvoiceDrafts();
    }
  }

  private loadInvoiceDrafts() {
    if (this.invoiceCustomerId) {
      this.getInvoiceDraftsRequest.invoiceCustomerId = this.invoiceCustomerId;
      this.getInvoiceDraftsRequest.state = "ALL" as GetInvoiceDraftsStateEnum;
    } else {
      this.getInvoiceDraftsRequest.state = "NEW" as GetInvoiceDraftsStateEnum;
    }

    console.log(this.getInvoiceDraftsRequest);

    new InvoiceDraftApi(AuthInterceptor.Instance)
      .getInvoiceDrafts(this.getInvoiceDraftsRequest)
      .subscribe(
        (response) => {
          this.invoiceDraftsLoading = false;
          this.invoiceDraftsLoaded = true;
          this.invoiceDrafts = response;
        },
        (error) => {
          eventHub.$emit("httpError", error);
          this.invoiceDraftsErrored = false;
        },
        () => {
          this.invoiceDraftsErrored = false;
        }
      );
  }

  private filterInvoiceDrafts(item: InvoiceDraft, filter: string) {
    return item.state?.includes(filter);
  }

  createNewInvoiceLine(
    invoiceDraft: InvoiceDraft,
    invoiceLineToPush: InvoiceLine
  ) {
    // Let's find the current invoice draft to add the new invoice line
    for (let i = 0; i < this.invoiceDrafts!.length; i++) {
      const draft = this.invoiceDrafts![i];

      if (draft.invoiceDraftId === invoiceDraft.invoiceDraftId) {
        if (draft.invoiceLines) {
          if (!draft.amountExpectedInvoiced) {
            draft.amountExpectedInvoiced = 0;
          }

          if (!invoiceLineToPush.price) {
            invoiceLineToPush.price = 0;
          }

          // Add price to expected invoiced
          this.invoiceDrafts![i].amountExpectedInvoiced = parseInt(
            (
              parseInt(draft.amountExpectedInvoiced.toString()) +
              parseInt(invoiceLineToPush.price.toString())
            ).toFixed(2)
          );

          if (!this.getInvoiceDrafts![i].invoiceLines) {
            this.getInvoiceDrafts![i].invoiceLines = [];
          }

          // Push to draft lines
          this.getInvoiceDrafts![i].invoiceLines!.push(invoiceLineToPush);
        }
      }
    }
  }

  deleteInvoiceLine(
    invoiceDraft: InvoiceDraft,
    invoiceLine: InvoiceLine,
    isExcluded: boolean
  ) {
    if (this.invoiceDrafts) {
      for (let i = 0; i < this.invoiceDrafts.length; i++) {
        const draft = this.invoiceDrafts[i];

        if (draft.invoiceDraftId === invoiceDraft.invoiceDraftId) {
          if (draft.invoiceLines) {
            if (!isExcluded) {
              for (let l = 0; l < draft.invoiceLines.length; l++) {
                const line = draft.invoiceLines[l];
                if (line.invoiceLineId === invoiceLine.invoiceLineId) {
                  if (!this.invoiceDrafts[i].invoiceLines) {
                    this.invoiceDrafts[i].invoiceLines = [];
                  }

                  if (!this.invoiceDrafts[i].amountExpectedInvoiced) {
                    this.invoiceDrafts[i].amountExpectedInvoiced = 0;
                  }

                  this.invoiceDrafts[i].amountExpectedInvoiced = parseInt(
                    (
                      this.invoiceDrafts[i].amountExpectedInvoiced! -
                      line.price!
                    ).toFixed(2)
                  );
                  this.invoiceDrafts[i].invoiceLines!.splice(l, 1);
                }
              }
            }
          }
        }
      }
    }
  }
}
